<template>
    <div style="padding-top: 8em;">
        <header-service :title="'Kontraktor dan Arsitek'" :subtitle="'Kami mewujudkan sesuai keinginan dan karakter masing-masing klien. Wujudkan sesuai keinginan anda. Bersama Legalpedia semua pasti bisa.'" :text_css="'text-left'"></header-service>
        <section class="section-100">
            <div class="container">
                <div class="top-over">
                    <h4 class="mb-5">Layanan</h4>
                    <v-row>
                        <div class="col-sm-12">
                            <div class="card border-0">
                                <div class="card-body rounded" style="background:#f5f6f8">
                                    <div class="row align-items-center">
                                    <div class="col-sm-4">
                                        <img src="@/assets/rumah-1.png" class="img-fluid">
                                    </div>
                                    <div class="col-sm-8">
                                        <p class="h2">
                                            Jasa Bangun Rumah
                                        </p>
                                        <p class="small mb-0 text-secondary">
                                            Kami jasa kontraktor Surabaya melayani pembangunan, konsultasi, perencanaan, penyusunan, dan teknik dalam menciptakan bangunan impian Anda. Pembangunan konstruksi yang kami kerjakan dapat disesuaikan dengan budget klien dan mengacu pada RAB (Rencana Anggaran Biaya) yang telah disepakati, selain sebagai perencana arsitek, kami juga yang akan menjadi kontraktor pelaksana, sehingga kualitas pekerjaan akan terjaga dari awal sampai serah terima kepada klien. Kami mengerjakan berbagai macam jenis bangunan, seperti seperti rumah tinggal, kost-kostan, ruko, kantor, gudang, café, restaurant dan lain lain.
                                        </p>
                                        <div class="text-left mt-3">
                                            <v-btn color="success" >
                                                Hubungi Kami
                                            </v-btn>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="card border-0">
                                <div class="card-body rounded" style="background:#f5f6f8">
                                    <div class="row align-items-center">
                                        <div class="col-sm-8">
                                            <p class="h2">
                                                Jasa Renovasi Rumah
                                            </p>
                                            <p class="small mb-0 text-secondary">
                                                Salah satu layanan kami yaitu jasa renovasi rumah. Didukung dengan team yang berpengalaman dan kompeten dibidangnya, juga di dukung oleh sumber daya manusia yang profesional dalam memberi pelayanan yang terbaik untuk klien kami. <br>
                                                Berikut macamnya:
                
                                            </p>
                                            <ol class="list-group list-group-numbered">
                                                <li class="small mb-0 text-secondary">Jasa Renovasi dan Perbaikan Rumah</li>
                                                <li class="small mb-0 text-secondary">Jasa Renovasi dan Perbaikan Kamar Mandi</li>
                                                <li class="small mb-0 text-secondary">Jasa Renovasi dan Perbaikan Atap / Genteng</li>
                                                <li class="small mb-0 text-secondary">Jasa Renovasi dan Perbaikan Lantai</li>
                                                <li class="small mb-0 text-secondary">Jasa Renovasi dan Perbaikan Pagar</li>
                                                <li class="small mb-0 text-secondary">Jasa Renovasi Meningkat Rumah</li>
                                                <li class="small mb-0 text-secondary">Jasa Renovasi Tambah Ruangan</li>
                                                <li class="small mb-0 text-secondary">Jasa Renovasi Membuat Dak</li>
                                                <li class="small mb-0 text-secondary">Jasa Pengecatan</li>
                                            </ol>
                                            <div class="text-left mt-3">
                                                <v-btn color="success" >
                                                    Hubungi Kami
                                                </v-btn>
                                            </div>
                                        </div>
                                        <div class="col-sm-4">
                                            <img src="@/assets/kontraktor-2.png" class="img-fluid">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="card border-0">
                                <div class="card-body rounded" style="background:#f5f6f8">
                                    <div class="row align-items-center">
                                    <div class="col-sm-4">
                                        <img src="@/assets/kontraktor-3.png" class="img-fluid">
                                    </div>
                                    <div class="col-sm-8">
                                        <p class="h2 mb-0">
                                            Jasa Interior
                                        </p>
                                        <p class="small mb-0 text-secondary">
                                            Kami menerima pembuatan segala macam Interior/Furniture, untuk seluruh daerah di Indonesia, diantara layanan kami sebagai berikut :
                                        </p>
                                        <ol class="list-group list-group-numbered">
                                            <li class="small mb-0 text-secondary">Kitchen Set</li>
                                            <li class="small mb-0 text-secondary">Wardrobe</li>
                                            <li class="small mb-0 text-secondary">Backdrop</li>
                                            <li class="small mb-0 text-secondary">Penyekat Ruang / Partisi</li>
                                            <li class="small mb-0 text-secondary">Tempat Tidur</li>
                                            <li class="small mb-0 text-secondary">Lemari Bawah Tangga</li>
                                            <li class="small mb-0 text-secondary">Semua jenis item pada interior</li>
                                        </ol>
                                        <p class="small mb-0 text-secondary">
                                            Kami menggunakan bahan baku dengan kualias terbaik yaitu :
                                        </p>
                                        <ol class="list-group list-group-numbered">
                                            <li class="small mb-0 text-secondary">Bahan Plywood / Multiplek Berkualitas</li>
                                            <li class="small mb-0 text-secondary">Finishing HPL (High Pressure Laminated)</li>
                                            <li class="small mb-0 text-secondary">Finishing Tursir Duco</li>
                                            <li class="small mb-0 text-secondary">Tukang yang puluhan tahun berpengalaman</li>
                                            <li class="small mb-0 text-secondary">Jati Belanda, Lantai Vynil, Wallpaper dan lainnya</li>
                                        </ol>
                                        <div class="text-left mt-3">
                                            <v-btn color="success" >
                                                Hubungi Kami
                                            </v-btn>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="card border-0">
                                <div class="card-body rounded" style="background:#f5f6f8">
                                    <div class="row align-items-center">
                                        <div class="col-sm-8">
                                            <p class="h2">
                                                Jasa Desain Arsitek
                                            </p>
                                            <p class="small mb-0 text-secondary">
                                                Menggunakan jasa arsitek desain rumah professional dapat menghemat waktu jika dibanding dengan mencari referensi desain dari internet. Desain rumah impian yang nyaman dipercaya dapat membantu menghilangkan stress setelah seharian lelah bekerja. Desain rumah akan menggambarkan kepribadian pemilik rumah dan karakter sosial pemilik rumah.
                
                                            </p>
                                            <div class="text-left mt-3">
                                                <v-btn color="success" >
                                                    Hubungi Kami
                                                </v-btn>
                                            </div>
                                        </div>
                                        <div class="col-sm-4">
                                            <img src="@/assets/kontraktor-4.jpg" class="img-fluid" style="min-height: 350px;">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="card border-0">
                                <div class="card-body rounded" style="background:#f5f6f8">
                                    <div class="row align-items-center">
                                    <div class="col-sm-4">
                                        <img src="@/assets/kontraktor-5.png" class="img-fluid">
                                    </div>
                                    <div class="col-sm-8">
                                        <p class="h2 mb-0">
                                            Jasa Pasang Kanopi
                                        </p>
                                        <p class="small mb-0 text-secondary">
                                            Jasa pasang kanopi yang berguna untuk tempat teduh dengan ruangan terbuka, sehingga bisa untuk tempat parkir motor atau mobil Anda agar terhindar dari panas dan hujan
                                        </p>
                                        <div class="text-left mt-3">
                                            <v-btn color="success" >
                                                Hubungi Kami
                                            </v-btn>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="card border-0">
                                <div class="card-body rounded" style="background:#f5f6f8">
                                    <div class="row align-items-center">
                                        <div class="col-sm-8">
                                            <p class="h2">
                                                Jasa Pasang Atap Galvalum
                                            </p>
                                            <p class="small mb-0 text-secondary">
                                                Jasa pemasangan rangka atap galvalum atau baja ringan rumah, kantor atau gudang yang anti rayap dan tidak mudah lapuk
                
                                            </p>
                                            <div class="text-left mt-3">
                                                <v-btn color="success" >
                                                    Hubungi Kami
                                                </v-btn>
                                            </div>
                                        </div>
                                        <div class="col-sm-4">
                                            <img src="@/assets/kontraktor-6.jpg" class="img-fluid" style="min-height: 350px;">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="card border-0">
                                <div class="card-body rounded" style="background:#f5f6f8">
                                    <div class="row align-items-center">
                                    <div class="col-sm-4">
                                        <img src="@/assets/kontraktor-7.jpg" class="img-fluid">
                                    </div>
                                    <div class="col-sm-8">
                                        <p class="h2 mb-0">
                                            Jasa Bangun Pagar
                                        </p>
                                        <p class="small mb-0 text-secondary">
                                            Jasa bangun pagar rumah, halaman ataupun pekarangan guna melindungi rumah anda.
                                        </p>
                                        <div class="text-left mt-3">
                                            <v-btn color="success" >
                                                Hubungi Kami
                                            </v-btn>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </v-row>
                </div>
            </div>
        </section>
        <contact-us></contact-us>
    </div>
</template>
<script>
import { mapState } from 'vuex'

import ContactUs from "@/components/layout_front/ContactUs.vue"
import HeaderService from "@/components/layout_front/HeaderService.vue"

export default {
components: {
    ContactUs, HeaderService
},
data: () => ({
    data : '',
    panel:[0,1,2,3],
    breadcumbs: [
        {
            text: 'Home',
            disabled: false,
            href: '/',
        },
        {
            text: 'Konsultasi Hukum',
            disabled: true,
        }
    ],
}),
methods:{
    
},
async mounted() { 
},
watch: {
}
}
</script>

<style scoped>

</style>